import { createTheme, alpha } from '@mui/material/styles'

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    fontWeightSemiBold: number
    leadText: React.CSSProperties
    smallText: React.CSSProperties
    leadParagraph: React.CSSProperties
    footerHeading: React.CSSProperties
    date: React.CSSProperties
    h1Small: React.CSSProperties
    h3Small: React.CSSProperties
    quote: React.CSSProperties
    subHeading: React.CSSProperties
  }
  interface TypographyOptions {
    fontWeightSemiBold?: number
    leadText?: React.CSSProperties
    smallText?: React.CSSProperties
    leadParagraph?: React.CSSProperties
    footerHeading?: React.CSSProperties
    date?: React.CSSProperties
    h1Small?: React.CSSProperties
    h3Small?: React.CSSProperties
    quote?: React.CSSProperties
    subHeading?: React.CSSProperties
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    fontWeightSemiBold: true
    leadText: true
    smallText: true
    leadParagraph: true
    footerHeading: true
    date: true
    h1Small: true
    h3Small: true
    quote: true
    subHeading: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    divider: string
    dividerLight: string
    lightGrey: string
    linkUnderline: string
  }
  interface PaletteOptions {
    divider?: string
    dividerLight?: string
    lightGrey?: string
    linkUnderline?: string
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    fixedHeroHeights: {
      xs: number
      sm: number
      md: number
      lg: number
      xl: number
    }
    shape?: {
      borderRadius?: number
      borderRadiusSmall?: number
      borderRadiusLarge?: number
    }
  }
  interface ThemeOptions {
    fixedHeroHeights: {
      xs: number
      sm: number
      md: number
      lg: number
      xl: number
    }
    shape?: {
      borderRadius?: number
      borderRadiusSmall?: number
      borderRadiusLarge?: number
    }
  }
}

const fonts = {
  sans: "'Inter', sans-serif;",
}

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

const colors = {
  primary: '#2a97d8',
  secondary: '#29345C',
  secondaryDark: '#212946',
  textPrimary: '#29345C',
  dividerLight: 'rgba(255,255,255,.2)',
  lightGrey: '#f3f4f7',
  linkUnderline: 'rgba(16,58,86,.14)',
}

const borderRadiuses = {
  small: 8,
  medium: 12,
  large: 14,
}

let theme = createTheme({
  fixedHeroHeights: {
    xs: 500,
    sm: 550,
    md: 600,
    lg: 700,
    xl: 800,
  },
  spacing: 10,
  shape: {
    borderRadius: borderRadiuses.medium,
    borderRadiusSmall: borderRadiuses.small,
    borderRadiusLarge: borderRadiuses.large,
  },
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      dark: colors.secondaryDark,
    },
    text: {
      primary: colors.textPrimary,
      secondary: alpha(colors.textPrimary, 0.8),
    },
    divider: alpha(colors.textPrimary, 0.12),
    dividerLight: colors.dividerLight,
    lightGrey: colors.lightGrey,
    linkUnderline: colors.linkUnderline,
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: fonts.sans,
    fontWeightLight: fontWeights.light,
    fontWeightRegular: fontWeights.regular,
    fontWeightMedium: fontWeights.medium,
    fontWeightSemiBold: fontWeights.semiBold,
    fontWeightBold: fontWeights.bold,
    body1: {
      lineHeight: 1.7,
    },
    h1: {
      fontWeight: fontWeights.bold,
    },
    h1Small: {
      fontWeight: fontWeights.bold,
      lineHeight: 1.3,
    },
    h2: {
      fontWeight: fontWeights.bold,
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: fontWeights.semiBold,
    },
    h3Small: {
      fontSize: '2rem',
      fontWeight: fontWeights.semiBold,
      lineHeight: 1.4,
    },
    leadText: {
      fontWeight: fontWeights.bold,
    },
    leadParagraph: {
      fontWeight: fontWeights.semiBold,
    },
    footerHeading: {
      fontWeight: fontWeights.semiBold,
    },
    date: {
      fontWeight: fontWeights.medium,
    },
    smallText: {
      fontWeight: fontWeights.medium,
      fontSize: '1.4rem',
      textTransform: 'uppercase',
      letterSpacing: '.18rem',
    },
    subHeading: {
      fontWeight: fontWeights.medium,
      textTransform: 'uppercase',
      letterSpacing: '.18rem',
      color: colors.primary,
    },
    quote: {
      fontWeight: fontWeights.regular,
      fontStyle: 'italic',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '1.6rem !important',
          fontWeight: fontWeights.bold,
          height: '60px',
          padding: '0 5rem',
        },
        sizeMedium: {
          fontSize: '1.6rem !important',
          fontWeight: fontWeights.bold,
        },
        text: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          alignItems: 'flex-start',
        },
        label: {
          paddingTop: '8px',
          fontSize: '1.6rem',
          textAlign: 'left',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem !important',
          color: colors.secondary,
          lineHeight: '3.2rem',
          transform: 'translate(24px, 16px) scale(1)',
        },
        asterisk: {
          color: `${colors.primary} !important`,
        },
        shrink: {
          color: `${colors.secondary} !important`,
          transform: 'translate(24px, -17px) scale(1)',
          ['+ div legend']: {
            // paddingRight: '16px !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: '1.6rem',
          backgroundColor: 'white',
          borderRadius: `${borderRadiuses.small}px !important`,
          padding: '22px',
          '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
            {
              WebkitBoxShadow: '0 0 0 300px white inset',
            },
          ['&.solid-dark']: {
            backgroundColor: colors.lightGrey,
          },
        },
        notchedOutline: {
          borderRadius: `${borderRadiuses.small}px !important`,
          borderWidth: '1px !important',
          borderColor: 'white',
          legend: {
            fontSize: '1.6rem',
            ['span']: {
              paddingLeft: '15px',
              paddingRight: '16px',
            },
          },
          ['&.Mui-focused']: {
            ['fieldset']: {
              borderColor: `${colors.primary} !important`,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: alpha(colors.secondary, 0.4),
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          borderColor: colors.dividerLight,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '36px',
          color: 'inherit',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: `${borderRadiuses.small}px !important`,
        },
        message: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.6rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          ['.MuiChip-label']: {
            fontSize: '1.2rem',
          },
        },
        sizeMedium: {
          height: 48,
          borderRadius: 24,
          marginLeft: '5px',
          marginRight: '5px',
          ['&.with-icon']: {
            paddingLeft: '20px',
            ['.MuiChip-label']: {
              paddingLeft: '20px',
              textTransform: 'none',
            },
          },
        },
        label: {
          fontSize: '1.6rem',
          fontWeight: fontWeights.medium,
          paddingLeft: 26,
          paddingRight: 26,
          textTransform: 'uppercase',
          letterSpacing: '.1rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {},
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '40px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: '40px',
          paddingRight: '40px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingBottom: '20px',
          paddingLeft: '40px',
          paddingRight: '40px',
        },
      },
    },
  },
})

// @ts-ignore
theme.components.MuiOutlinedInput.styleOverrides.root = {
  // @ts-ignore
  ...theme.components.MuiOutlinedInput.styleOverrides.root,
  backgroundColor: 'white',
  ['&.solid-dark']: {
    backgroundColor: colors.lightGrey,
  },
  ['&.valid']: {
    notchedOutline: {
      borderColor: `${theme.palette.success.main} !important`,
    },
  },

  ['fieldset']: {
    transition: theme.transitions.create(['border-color']),
  },
  ['&:hover fieldset']: {
    borderColor: `${alpha(colors.secondary, 0.2)} !important`,
  },
  ['&.Mui-focused']: {
    ['&:hover fieldset']: {
      borderColor: `${colors.primary} !important`,
    },
  },
}

// REPONSIVE TYPOGRAPHY

theme.typography = {
  ...theme.typography,
  fontSize: 14,
  [theme.breakpoints.up('sm')]: {
    fontSize: 15,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 17,
  },
}

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '7.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '8.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '9.8rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '10.8rem',
  },
}
theme.typography.h1Small = {
  ...theme.typography.h1Small,
  fontSize: '3.6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '6rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '7rem',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '3.2rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '4.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '4.4rem',
  },
}

// @ts-ignore
theme.components.MuiCardContent.styleOverrides.root = {
  // @ts-ignore
  ...theme.components.MuiCardContent.styleOverrides.root,
  padding: theme.spacing(4),
}

// @ts-ignore
theme.components.MuiButton.styleOverrides.root = {
  // @ts-ignore
  ...theme.components.MuiButton.styleOverrides.root,
  fontFamily: fonts.sans,
  fontWeight: fontWeights.semiBold,
  textTransform: 'none',
  boxShadow: 'none',
  whiteSpace: 'nowrap',
  ['&:hover']: {
    boxShadow: 'none',
  },
  borderRadius: '5px',
  [theme.breakpoints.up('sm')]: {
    borderRadius: '7px',
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: '9px',
  },
  [theme.breakpoints.up('lg')]: {
    borderRadius: '10px',
  },
  [theme.breakpoints.up('xl')]: {
    borderRadius: '10px',
  },
}

// @ts-ignore
theme.components.MuiButton.styleOverrides.sizeMedium = {
  // @ts-ignore
  ...theme.components.MuiButton.styleOverrides.sizeMedium,
  paddingLeft: '2rem',
  paddingRight: '2rem',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '2.2rem',
    paddingRight: '2.2rem',
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: '2.4rem',
    paddingRight: '2.4rem',
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '2.6rem',
    paddingRight: '2.6rem',
  },
  [theme.breakpoints.up('xl')]: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
}
// @ts-ignore
theme.components.MuiButton.styleOverrides.sizeLarge = {
  // @ts-ignore
  ...theme.components.MuiButton.styleOverrides.sizeLarge,
  fontSize: '1.6rem !important',
  fontWeight: fontWeights.bold,
  height: '70px',
  padding: '0 5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.65rem !important',
    height: '76px',
    padding: '0 5.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.7rem !important',
    height: '82px',
    padding: '0 5.4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.75rem !important',
    height: '92px',
    padding: '0 5.6rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.8rem !important',
    height: '98px',
    padding: '0 6rem',
  },
}

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.55rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.8rem',
  },
}

theme.typography.subHeading = {
  ...theme.typography.subHeading,
  fontSize: '1.4rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.45rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.55rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.6rem',
  },
}
theme.typography.leadText = {
  ...theme.typography.leadText,
  fontSize: '2.2rem',
  lineHeight: 1.4,
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.2rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '3.6rem',
  },
}
theme.typography.leadParagraph = {
  ...theme.typography.leadParagraph,
  fontSize: '1.6rem',
  lineHeight: 1.7,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.4rem',
  },
}
theme.typography.date = {
  ...theme.typography.date,
  fontSize: '1.4rem',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.4rem',
  },
}

theme.typography.footerHeading = {
  ...theme.typography.footerHeading,
  fontSize: '1.5rem',
  marginBottom: '2px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.55rem',
    marginBottom: '4px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
    marginBottom: '8px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.65rem',
    marginBottom: '14px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.7rem',
    marginBottom: '20px',
  },
}
theme.typography.quote = {
  ...theme.typography.quote,
  fontSize: '2.2rem',
  lineHeight: 1.6,
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.25rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.3rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.35rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.4rem',
  },
}

export default theme
