import * as React from 'react'

type Action = {
  type: 'toggleSidebar' | 'closeSidebar'
}
type Dispatch = (action: Action) => void
type State = {
  open: boolean
}
type NavDrawerProviderProps = { children: React.ReactNode }

const NavDrawerContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function navDrawerReducer(state: State, action: Action) {
  switch (action.type) {
    case 'toggleSidebar': {
      state.open = !state.open
      return {
        ...state,
      }
    }
    case 'closeSidebar': {
      state.open = false
      return {
        ...state,
      }
    }
  }
}

function NavDrawerProvider({ children }: NavDrawerProviderProps) {
  const [state, dispatch] = React.useReducer(navDrawerReducer, {
    open: false,
  })
  const value = { state, dispatch }
  return (
    <NavDrawerContext.Provider value={value}>
      {children}
    </NavDrawerContext.Provider>
  )
}

function useNavDrawer() {
  const context = React.useContext(NavDrawerContext)
  if (context === undefined) {
    throw new Error('useNavDrawer must be used within a NavDrawerProvider')
  }
  return context
}

export { NavDrawerProvider, useNavDrawer }
