import { useTheme } from '@mui/material'

const globalStyles = () => {
  const theme = useTheme()
  return {
    html: {
      fontSize: '62.5%;',
      backgroundColor: theme.palette.secondary.main,
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    body: {
      backgroundColor: theme.palette.secondary.main,
    },
  }
}

export default globalStyles
