import React from 'react'
import { MessageProvider } from 'context/MessageContext'
import { NavDrawerProvider } from 'context/NavDrawerContext'
import { ThemeProvider, GlobalStyles, CssBaseline } from '@mui/material'
import { AnimatePresence } from 'framer-motion'

import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import globalStyles from 'constants/global-styles.tsx'
import theme from 'constants/theme'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    const ss = await import('smoothscroll-polyfill')
    ss.polyfill()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <MessageProvider>
      <NavDrawerProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          {element}
        </ThemeProvider>
      </NavDrawerProvider>
    </MessageProvider>
  )
}

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
)
