exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-donor-campaign-landing-tsx": () => import("./../../../src/templates/DonorCampaignLanding.tsx" /* webpackChunkName: "component---src-templates-donor-campaign-landing-tsx" */),
  "component---src-templates-donor-campaign-tsx": () => import("./../../../src/templates/DonorCampaign.tsx" /* webpackChunkName: "component---src-templates-donor-campaign-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-info-page-tsx": () => import("./../../../src/templates/InfoPage.tsx" /* webpackChunkName: "component---src-templates-info-page-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/NewsArticle.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-news-events-landing-tsx": () => import("./../../../src/templates/NewsEventsLanding.tsx" /* webpackChunkName: "component---src-templates-news-events-landing-tsx" */),
  "component---src-templates-patient-story-tsx": () => import("./../../../src/templates/PatientStory.tsx" /* webpackChunkName: "component---src-templates-patient-story-tsx" */),
  "component---src-templates-share-story-page-tsx": () => import("./../../../src/templates/ShareStoryPage.tsx" /* webpackChunkName: "component---src-templates-share-story-page-tsx" */),
  "component---src-templates-story-landing-tsx": () => import("./../../../src/templates/StoryLanding.tsx" /* webpackChunkName: "component---src-templates-story-landing-tsx" */),
  "component---src-templates-supporter-landing-tsx": () => import("./../../../src/templates/SupporterLanding.tsx" /* webpackChunkName: "component---src-templates-supporter-landing-tsx" */)
}

